import React from "react";
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Features from "../components/Features";

// eslint-disable-next-line
export const IndexPageTemplate = ({ title, description, pealkiri, alaPealkiri, intro, introPealkiri, introTutvustusTekst, wrapper }) => {

  return (
    <>
      <Helmet
        title={title}
        meta={[
          { name: 'description', content: description },
        ]}
      >
      </Helmet>
      <section id="banner">
        <div className="inner">
          <h2>{pealkiri}</h2>
          <p>{alaPealkiri}</p>
        </div>
      </section>

      <section id="wrapper">
        <section id="one" className="wrapper spotlight style1">
          <div className="inner">
            <GatsbyImage className="image" image={getImage(wrapper.blurbs[0].image.gatsbyImageData)} alt="Meist leht - ettevõtte tutvustus" />
            <div className="content">
              <h2 className="major">{wrapper.blurbs[0].pealkiri}</h2>
              <p>
                {wrapper.blurbs[0].text}
              </p>
              <Link
                className="special"
                to="/meist"
              >
                Loe edasi
              </Link>
            </div>
          </div>
        </section>

        <section id="two" className="wrapper alt spotlight style2">
          <div className="inner">
            <GatsbyImage className="image" image={getImage(wrapper.blurbs[1].image.gatsbyImageData)} alt="Dokumendihalduse tarkvara tutvustus" />
            <div className="content">
              <h2 className="major">{wrapper.blurbs[1].pealkiri}</h2>
              <p>
                {wrapper.blurbs[1].text}
              </p>
              <Link
                className="special"
                to="/a-dok"
              >
                Loe edasi
              </Link>
            </div>
          </div>
        </section>

        <section id="three" className="wrapper spotlight style3">
          <div className="inner">
            <GatsbyImage className="image" image={getImage(wrapper.blurbs[2].image.gatsbyImageData)} alt="Partnerile ja tööotsijale" />
            <div className="content">
              <h2 className="major">{wrapper.blurbs[2].pealkiri}</h2>
              <p>
                {wrapper.blurbs[2].text}
              </p>
              <Link
                className="special"
                to="/partnerile"
              >
                Loe edasi
              </Link>
            </div>
          </div>
        </section>

        <section id="four" className="wrapper alt style1">
          <div className="inner">
            <h2 className="major">{introPealkiri}</h2>
            <p>
              {introTutvustusTekst}
            </p>
            <Features gridItems={intro.blurbs} />
          </div>
        </section>
      </section>
    </>
  );
};


IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pealkiri: PropTypes.string,
  alaPealkiri: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  introPealkiri: PropTypes.string,
  introTutvustusTekst: PropTypes.string,
  wrapper: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = () => {
  const data = useStaticQuery(graphql`
  query IndexPage {
  markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
    frontmatter {
      intro {
        blurbs {
          heading
          text
          image
        }
      }
       wrapper {
        blurbs {
          image
          pealkiri
          text
        }
      }
      templateKey
      description
      alaPealkiri
      pealkiri
      title
      introPealkiri
      introTutvustusTekst
    }
  }
    allImageSharp {
    nodes {
      gatsbyImageData(
        quality: 100
        layout: CONSTRAINED
        placeholder: BLURRED
      )
    }
  }
}
`)

  const images = data.allImageSharp.nodes;
  const introBlurbs = data.markdownRemark.frontmatter.intro.blurbs;
  const wrapperBlurbs = data.markdownRemark.frontmatter.wrapper.blurbs;

  const imagesArray = [];
  images.forEach(image => {
    imagesArray.push(image.gatsbyImageData.images.fallback.src);
  });
  introBlurbs.forEach(blurb => {
    if (typeof blurb.image === 'string') {
      let string = blurb.image;
      let result = string.substring(string.lastIndexOf("/") + 1);
      images.forEach(image => {
        if (image.gatsbyImageData.images.fallback.src.includes(result)) {
          blurb.image = image
        }
      });
    }
  });
  wrapperBlurbs.forEach(blurb => {
    if (typeof blurb.image === 'string') {
      let string = blurb.image;
      let result = string.substring(string.lastIndexOf("/") + 1);
      images.forEach(image => {
        if (image.gatsbyImageData.images.fallback.src.includes(result)) {
          blurb.image = image
        }
      });
    }
  });


  return (
    <Layout>
      <IndexPageTemplate
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
        pealkiri={data.markdownRemark.frontmatter.pealkiri}
        alaPealkiri={data.markdownRemark.frontmatter.alaPealkiri}
        intro={data.markdownRemark.frontmatter.intro}
        introPealkiri={data.markdownRemark.frontmatter.introPealkiri}
        introTutvustusTekst={data.markdownRemark.frontmatter.introTutvustusTekst}
        wrapper={data.markdownRemark.frontmatter.wrapper}
      />
    </Layout>
  );
};


IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};


export default IndexPage;